// JsFromRoutes CacheKey 31116ac97c1df807cb3122588a878a67
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  index: /* #__PURE__ */ definePathHelper('get', '/clips'),
  create: /* #__PURE__ */ definePathHelper('post', '/clips'),
  new: /* #__PURE__ */ definePathHelper('get', '/clips/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/clips/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/clips/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/clips/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/clips/:id'),
  finalizeQuestionnaire: /* #__PURE__ */ definePathHelper('post', '/clips/:id/finalize_questionnaire'),
  sync: /* #__PURE__ */ definePathHelper('post', '/clips/:id/sync'),
}
